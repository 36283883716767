import { gql } from '@apollo/client';

const GET_ALL_LOCATIONS = gql`
  query GetAllLocations($filter: LocationFilter, $limit: Int, $skip: Int) {
    getAllLocations(filter: $filter, limit: $limit, skip: $skip) { 
        _id 
        name
        operationType
        fitForDutyPercent
        roadReadyPercent
        numFtEmployees
        numPtEmployees
        ownedOrLeased
        addressState
        outlierCount
    }
  }
`;

export default GET_ALL_LOCATIONS;
export { GET_ALL_LOCATIONS };