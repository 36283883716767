// import SearchBar from '../searchTailwind/index';
// import ProfileIconTailwind from '../profileIconTailwind';

import LeftNavBar from '../leftNavBar/index';
import LocationIcon from '../../assets/itw_locations_nav_bar_icon.svg';
import Drivers from '../../assets/employees-icon-white.svg';
import Dashboard from '../../assets/dashboardIconLeftNav.svg';
import Logo from "../logo";
import './mainTemplate.css';


const MainTemplate = () => {

  const navItems =[
    {
      href: "/dashboard",
      icon: Dashboard, 
      label: "Dashboard"
    }, 
    {
      href: "/location",
      icon: LocationIcon, 
      label: "Locations"
    }, 
    {
      href: "/employeePage",
      icon: Drivers, 
      label: "Drivers"
    }, 
    // {
    //   href: "/vehiclepage",
    //   icon: TheCarIcon, 
    //   label: "Vehicles"
    // },
    // {
    //   href: "#",
    //   icon: Document, 
    //   label: "System Logs"
    // }, 
    // {
    //   href: "#",
    //   icon: Logout,  
    //   label: "Logout"
    // }, 
  ]
  
    return (
      <div>
        <LeftNavBar items={navItems}/>
        <div className="logo">
            <Logo />
        </div>
      </div>
    );
  };
  
  export default MainTemplate;