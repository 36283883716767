// src/components/VehicleInfo.js
import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';

const DECODE_VIN = gql`
  query DecodeVin($vin: String!) {
    decodeVin(vin: $vin) {
      VIN
      Make
      Model
      ModelYear
      BodyClass
      VehicleType
    }
  }
`;

type VehicleInfoProps = {
  onVehicleDataFetched: (data: any) => void;
};

const VehicleInfo = ({ onVehicleDataFetched }: VehicleInfoProps) => {
  const [vin, setVin] = useState('');
  console.log('vin', vin);
  const { loading, error, data, refetch } = useQuery(DECODE_VIN, {
    variables: { vin },
    skip: !vin,
  });

  useEffect(() => {
    if (data && data.decodeVin) {
      onVehicleDataFetched(data.decodeVin);
    }
  }, [data, onVehicleDataFetched]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (vin) {
      refetch();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={vin}
          onChange={(e) => setVin(e.target.value)}
          placeholder="Enter VIN"
        />
        {/* <button type="submit">Decode VIN</button> */}
      </form>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {/* {data && (
        <div>
          <h3>Vehicle Information</h3>
          <p>VIN: {data.decodeVin.VIN}</p>
          <p>Make: {data.decodeVin.Make}</p>
          <p>Model: {data.decodeVin.Model}</p>
          <p>Model Year: {data.decodeVin.ModelYear}</p>
          <p>Body Class: {data.decodeVin.BodyClass}</p>
          <p>Vehicle Type: {data.decodeVin.VehicleType}</p>
        </div>
      )} */}
    </div>
  );
};

export default VehicleInfo;
