import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUploadContext } from '../../../context/uploadProvider';
import Webcam from 'react-webcam';
import Header from '../progressDots';
import './capture.css';

const DriverLicenseCapture = () => {
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState<null | string | undefined>(null);
  const { captureSide, nextCaptureSide, setCaptureSide, sidesCapturedStatus, setSidesCapturedStatus, uploadType, setUploadType  } = useUploadContext();

  useEffect(() => {
    // Clear the captured image when the component mounts
    setImgSrc(null);
  }, [captureSide]);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = (webcamRef.current as Webcam | null)?.getScreenshot();
      setImgSrc(imageSrc);
    }
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  const confirm = () => {
    if (uploadType === 'driverLicense') {
      setSidesCapturedStatus({ ...sidesCapturedStatus, [captureSide]: true });
      if (sidesCapturedStatus.front && sidesCapturedStatus.back) {
        navigate('/mobileDriverOnboard/profile');
      } else {
        setCaptureSide(nextCaptureSide);
        navigate('/mobileDriverOnboard/capture');
      }
    } else if (uploadType === 'insuranceCard') {
      setSidesCapturedStatus({ ...sidesCapturedStatus, [captureSide]: true });
      if (sidesCapturedStatus.front && sidesCapturedStatus.back) {
        navigate('/mobileDriverOnboard/confirmation');
      } else {
        setCaptureSide(nextCaptureSide);
        navigate('/mobileDriverOnboard/capture');
      }
    }
  };

  const onCancel = () => {
    navigate('/mobileDriverOnboard/cancel')
  }

  return (
    <div className="driver-license-capture mobile-main">
      <Header />
      <div className='mobile-body'>
      <h2 className='capture-h2'>Upload {uploadType === 'driverLicense' ? `Driver's License ${captureSide === 'front' ? 'Front' : 'Back'}` : `Insurance ${captureSide === 'front' ? 'Front' : 'Back'}`}</h2>
        <div className="capture-area">
          {imgSrc ? (
            <img src={imgSrc} alt="Captured" />
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={'auto'}
              style={{borderRadius: '20px'}}
              videoConstraints={{ facingMode: "environment" }}
            />
          )}
        </div>

        <div className="capture-button-container">
          {imgSrc ? (
            <>
              <button className="capture-button" onClick={retake}>RETAKE</button>
            </>
          ) : (
            <button className="capture-button" onClick={capture}>CAPTURE</button>
          )}
          <button className="capture-cancel-button" onClick={onCancel}>CANCEL</button>
        </div>

        {imgSrc && (
          <div className="confirm-button-container">
            <button className="capture-button confirm-button" onClick={confirm}>CONFIRM</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverLicenseCapture;
