import { useNavigate } from 'react-router-dom';
import Header from '../progressDots';
import './personalVehicle.css';  

function PersonalVehicle(): JSX.Element {
  const navigate = useNavigate();

  const handleYes = () => {
    navigate('/mobileDriverOnboard/personal-vehicle-info');
  }
  const handleNo = () => {
    navigate('/mobileDriverOnboard/confirmation');
  }
  return (
    <div className='mobile-main vehicle-setup'>
        <Header />
      <div className="mobile-body ">
        {/* <h2 className="personal-vehicle-h2">Vehicle</h2> */}
      </div>
      <div className="body question">
        Will you be driving a personal vehicle?
      </div>
      <div className="personal-vehicle-button-container">
        <button className="personal-vehicle-button" onClick={handleYes}>YES</button>
        <button className="personal-vehicle-button" onClick={handleNo}>NO</button>
      </div>
    </div>
  );
}

export default PersonalVehicle;
