import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { OverdueTasksProvider } from './context/overdueTasksContext';

import Location from './pages/locations';
import DetailLocation from './pages/detailLocation';
import MainTemplate from './components/mainTemplate/index'
import ListTemplate from './components/listTemplate/index'
// import VehiclePage from './pages/vehicle.tsx'
import EmployeePage from './pages/employee';
import EmployeeDetail from './pages/detailEmployee';
import Dashboard from './pages/dashboard'
import LoginPage from './pages/login'
import LocationAnalytics from './pages/locationAnalytics';
import DriverAnalytics from './pages/driverAnalytics';
import DashboardAnalytics from './pages/dashboardAnalytics';
import MobileDriverOnboard from './pages/mobileDriverOnboard';

function App() {
  return (
    // <OverdueTasksProvider>
      <Router>
        <Routes>
          <Route path="/location" element={<Location />} />
          <Route path="/detailLocation/:id" element={<DetailLocation />} />
          <Route path="/mainTemplate" element={<MainTemplate />} />
          <Route path="/listTemplate" element={<ListTemplate />} />
          <Route path="/employeePage" element={<EmployeePage />} />
          <Route path="/employeedetail/:id" element ={<EmployeeDetail/>}/>
          <Route path="/" element = {<LoginPage />}/>
          {/* <Route path ="/dashboard" element = {<Dashboard/>}/> */}
          <Route path ="/mobileDriverOnboard/*" element = {<MobileDriverOnboard/>}/>
          <Route 
          path="/dashboard" 
          element={
            <OverdueTasksProvider>
              <Dashboard />
            </OverdueTasksProvider>
          } 
        />
          <Route path ="/locationAnalytics" element = {<LocationAnalytics/>}/>
          <Route path ="/driverAnalytics" element = {<DriverAnalytics/>}/>
          {/* <Route path ="/dashboardAnalytics" element = {<DashboardAnalytics/>}/> */}
          <Route 
          path="/dashboardAnalytics" 
          element={
            <OverdueTasksProvider>
              <DashboardAnalytics />
            </OverdueTasksProvider>
          } 
        />
        </Routes>
      </Router>
    // </OverdueTasksProvider>
  );
}

export default App;

