import {useState} from 'react';
import './personalVehicleInformation.css';  // Import the component-specific CSS file
import Header from '../progressDots';
import VehicleInfo from '../decodeVin';  
import { useNavigate } from 'react-router-dom';
import { useUploadContext } from '../../../context/uploadProvider';

function PersonalVehicleInformation(): JSX.Element {
  const navigate = useNavigate();
  const { setUploadType, setCaptureSide, setNextCaptureSide } = useUploadContext();

  const [year, setYear] = useState('');
  const [make, setMake] = useState('');
  const [yearError, setYearError] = useState('');
  const [makeError, setMakeError] = useState('');
  const [model, setModel] = useState('');
  const [modelError, setModelError] = useState('');
  const [series, setSeries] = useState('');
  const [seriesError, setSeriesError] = useState('');
  const [vin, setVin] = useState('');
  const [vinError, setVinError] = useState('');
  const [vehicleData, setVehicleData] = useState(null)

  const validateYear = (year: string) => {
    const yearRegex = /^(19|20)\d{2}$/;
    if (!yearRegex.test(year)) {
      setYearError('Year must be a 4-digit number as 19-- or 20--.');
      return false;
    }
    setYearError('');
    return true;
  };

  const validateMake = (make: string) => {
    if (!make) {
      setMakeError('Make cannot be empty.');
      return false;
    }
    setMakeError('');
    return true;
  };

  const validateModel = (model: string) => {
    if (!model) {
      setModelError('Model cannot be empty.');
      return false;
    }
    setModelError('');
    return true;
  };

  const validateSeries = (series: string) => {
    if (!series) {
      setSeriesError('Series cannot be empty.');
      return false;
    }
    setSeriesError('');
    return true;
  };

  const validateVin = (vin: string) => {
    if (vin.length !== 17) {
      setVinError('VIN must be 17 characters long.');
      return false;
    }
    setVinError('');
    return true;
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYear(e.target.value);
    if (yearError) {
      validateYear(e.target.value);
    }
  };


  const handleMakeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMake(e.target.value);
    if (makeError) {
      validateMake(e.target.value);
    }
  };

  const handleModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value);
    if (modelError) {
      validateModel(e.target.value);
    }
  };

  const handleSeriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeries(e.target.value);
    if (seriesError) {
      validateSeries(e.target.value);
    }
  };

  const handleVinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVin(e.target.value);
    if (vinError) {
      validateVin(e.target.value);
    }
  };

  const onContinue = () => {
    const isYearValid = validateYear(year);
    const isMakeValid = validateMake(make);
    const isModelValid = validateModel(model);
    const isSeriesValid = validateSeries(series);
    // const isVinValid = validateVin(vin);
    if (isYearValid && isMakeValid && isModelValid && isSeriesValid ) {
      setUploadType('insuranceCard');
      // setCaptureSide('front');
      // setNextCaptureSide('back');
      navigate('/mobileDriverOnboard/upload');
    }
  }

  const onBack = () => {
    navigate('/mobileDriverOnboard/personal-vehicle');
  }

  const handleVehicleDataFetched = (data: any) => {
    setVehicleData(data);
    if (data) {
      setYear(data.ModelYear);
      setMake(data.Make);
      setModel(data.Model);
      setSeries(data.BodyClass);
    }
  };

  return (
    <div className='mobile-main vehicle-info-setup'>
      <Header />
      <div className="mobile-body">
        <h2 className="vehicle-info-info-h2">Personal Vehicle Information</h2>
        {/* <h3>Enter your VIN, we will do the rest.</h3> */}
        <div className="vehicle-info-form-container">
          <div className="vehicle-info-form-group">
          {/* {vinError && <div className="personal-vehicle-info-error-message">{vinError}</div>} */}
          <VehicleInfo onVehicleDataFetched={handleVehicleDataFetched}/>

          {yearError && <div className="personal-vehicle-info-error-message">{yearError}</div>}
            <input 
              type="text" 
              placeholder="Year" 
              value={year}
              onChange={handleYearChange}
              onBlur={() => validateYear(year)}
            />
            {makeError && <div className="personal-vehicle-info-error-message">{makeError}</div>}
          <input 
            type="text" 
            placeholder="Make" 
            value={make}
            onChange={handleMakeChange}
            onBlur={() => validateMake(make)}
          />
          {modelError && <div className="personal-vehicle-info-error-message">{modelError}</div>}
          <input 
            type="text" 
            placeholder="Model" 
            value={model}
            onChange={handleModelChange}
            onBlur={() => validateModel(model)}
          />
          {seriesError && <div className="personal-vehicle-info-error-message">{seriesError}</div>}
          <input 
            type="text" 
            placeholder="Series" 
            value={series}
            onChange={handleSeriesChange}
            onBlur={() => validateSeries(series)}
          />
          </div>
            {/* <input 
              type="text" 
              placeholder="VIN" 
              value={vin}
              onChange={handleVinChange}
              onBlur={() => validateVin(vin)}
            /> */}
        </div>
        <div className="vehicle-info-button-container">
          <button className="vehicle-info-continue-button" onClick={onContinue}>CONTINUE</button>
          <button className="vehicle-info-back-button" onClick={onBack}>BACK</button>
        </div>
      </div>
    </div>
  );
}

export default PersonalVehicleInformation;
