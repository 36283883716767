import { useNavigate } from 'react-router-dom';
import './authenticate.css';  // Import the CSS file
import '../../../css/mobileDriverOnboard.css';  // Import the CSS file
import LogoMobile from "../../logoMobile";

function MobileAuthenticate(): JSX.Element {
  const navigate = useNavigate();

  const handleSubmit = () => {
    // Validate the passcode (this is a placeholder, you should add actual validation logic)
    // if (passcodeIsValid) {
    navigate('/mobileDriverOnboard/terms-conditions');
    // }
  };

  return (
    <div className='mobile-driver-onboard mobile-main'>
      <div className="mobile-header">
        <div className="logo-size">
          <LogoMobile />
        </div>
        <div className="welcome-message">
          WELCOME RESIN TEAM!
        </div>
      </div>
      <div className="mobile-body">
        <div className="input-container">
          <input 
            type="email" 
            id="email" 
            placeholder="Passcode" 
          />
        </div>
        <div className="authenticate-button-container">
          <button 
            className="submit-button"
            onClick={handleSubmit}
          >SUBMIT</button>
          <button className="cancel-button">CANCEL</button>
        </div>
      </div>
    </div>
  );
}

export default MobileAuthenticate;
