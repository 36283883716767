import { gql } from '@apollo/client';

const GET_ALL_EMPLOYEES = gql`
  query GetAllEmployees($filter: EmployeeFilter) {
    getAllEmployees(filter: $filter) { 
        _id
        location
        userId
        firstName
        middleName
        lastName
        addressStreet
        addressCity
        addressState
        addressZip
        phone 
        # ffdStatus
        outlierCount
        emailCompany
        driversLicenseNum
        driversLicenseExpiration
        # mvrScore
        relationshipToEmployee
       
        # program{
        #   _id
        # }
    }, 
}`

export default GET_ALL_EMPLOYEES;
export { GET_ALL_EMPLOYEES };