import { useNavigate } from 'react-router-dom';
import { useUploadContext } from '../../../context/uploadProvider';
import './termsConditions.css';  // Import the CSS file
import LogoMobile from "../../logoMobile";

function TermsConditions(): JSX.Element {

  const navigate = useNavigate();
  const { setUploadType } = useUploadContext();

  const handleAgree = () => {
    setUploadType('driverLicense');
    navigate('/mobileDriverOnboard/upload');
  };

  return (
    <>
    <div className="terms-and-conditions mobile-main">
      <div className='mobile-header'>
        <LogoMobile />
      </div>
      <div className='mobile-body'>
        <button className="mobile-back-button" onClick={() => window.history.back()}>←</button>
        <h1 className="terms-h1">In the Weeds</h1>
        <h2 className="terms-h2">User Agreement</h2>
        <div className="terms-text">
          <h3>A. User Terms and Agreement</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          <h3>B. Morbi placerat felis ultricies lacus sed turpis tincidunt.</h3>
          <p>Phasellus id velit vel eros volutpat adipiscing. Phasellus in erat ut odio suscipit sollicitudin. Phasellus tincidunt purus nec diam elementum, non congue elit pulvinar.</p>
          <h3>C. Lacus sed viverra</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
        </div>
      <div className="terms-button-container">
        <button className="terms-accept-button" onClick={handleAgree}>I Agree</button>
        <button className="terms-back-button">I Disagree</button>
      </div>
      </div>
    </div>
    </>
  );
}

export default TermsConditions;
