import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import React, { useState, FormEvent } from 'react';
import '../css/App.css';
import Login from '../components/login/index'
import { gql, useMutation } from '@apollo/client';
import ForgotPassword from '../components/forgotPassword';

const AUTHENTICATE_USER=gql`
  mutation authenticateUser($email: String!, $password: String!){
    authenticateUser(email: $email, password: $password){
      token
      user{
        _id
        email
        role
      }
    }
  }
`;
// Define props if we need to pass any to the LoginForm component
// interface LoginProps {
//   email: string
//   password: string
//   onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void
//   onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void
//   onSubmit: (event: FormEvent) => void
// }

function LoginPage() {

const [email, setEmail] = useState<string>('');
const [password, setPassword] = useState<string>('');

console.log("email, password", email, password)
const navigate = useNavigate();

const [authenticateUser, {data, loading, error}] = useMutation(AUTHENTICATE_USER
  // variables: { email, password }, 
  // onCompleted:(data) => {
  //   console.log("Authentication success:", data);
  // }, 
  // onError: (error) => {
  //   console.error("Authentication error:", error);
  // }
);
if (loading) return <p>Loading...</p>;
if (error) return <p>Error: {error.message}</p>;
console.log("data", data)
console.log("authenticateUser", authenticateUser)

const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setEmail(event.target.value);
};

const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setPassword(event.target.value);
};

// const handleSubmit = (event: FormEvent) => {
//   event.preventDefault();
//   authenticateUser();
// }

const handleSubmit = async (event:any)=> {
  console.log("submit")
  event.preventDefault(); 
  try{
    const {data} = await authenticateUser({
      variables : {
        email: email, 
        password: password, 
      },
    });
    console.log("Authentication success:", data);
    if (data && data.authenticateUser && data.authenticateUser.token) {
      localStorage.setItem('token', data.authenticateUser.token);
      if(data.authenticateUser.user.role === 'Manager'){
        navigate('/dashboard')
      }
    }    
  }catch(error){
    console.error("Authentication error:", error);
  }
}
//create a jwt secret key and store it in an environmental variable 
//on click of submit, send the password and email in state to the server at this endpoint: getUser

//1. user login: when a user clicks signin button
  //a. authenticate their credentials.  find user by email, and match passwords using bcrypt 
//2. generate a jwt with claims about identity 
//3. embedd user role in jwt when the jwt is generated
//4. secure mutations and queries by requiring jwt's validation to access them
//5. implement jwt as a middleware that checks for validation of the token and extracts the user role -- do this in app.js


  return (
    <>
      <Login
        email={email}
        password={password}
        handleEmailChange={handleEmailChange}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
      />
      {/* <ForgotPassword /> */}
    </>
  );
}

export default LoginPage;
