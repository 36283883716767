import { Routes, Route } from 'react-router-dom';
import { UploadProvider } from '../context/uploadProvider';
import MobileAuthenticate from '../components/mobileDriverOnboard/authenticate';
import TermsConditions from '../components/mobileDriverOnboard/termsConditions';
import DriverLicenseUpload from '../components/mobileDriverOnboard/upload';
import DriverLicenseCapture from '../components/mobileDriverOnboard/capture';
import Profile from '../components/mobileDriverOnboard/driverProfile';
import PersonalVehicle from '../components/mobileDriverOnboard/personalVehicle';
import PersonalVehicleInformation from '../components/mobileDriverOnboard/personalVehicleInformation';
import VehicleInfo from '../components/mobileDriverOnboard/decodeVin';
import Cancel from '../components/mobileDriverOnboard/cancel';
import Confirmation from '../components/mobileDriverOnboard/confirmation';
import '../css/mobileDriverOnboard.css'; 

function MobileDriverOnboard(): JSX.Element {
  return (
    <UploadProvider>
      <Routes>
        <Route path="authenticate" element={<MobileAuthenticate />} />
        <Route path="terms-conditions" element={<TermsConditions />} />
        <Route path="upload" element={<DriverLicenseUpload />} />
        <Route path="capture" element={<DriverLicenseCapture />} />
        <Route path="profile" element={<Profile />} />
        <Route path="personal-vehicle" element={<PersonalVehicle />} />
        <Route path="personal-vehicle-info" element={<PersonalVehicleInformation />} />
        <Route path="confirmation" element={<Confirmation />} />
        <Route path="/" element={<MobileAuthenticate />} /> {/* Default Route */}
        <Route path="decode-vin" element={<VehicleInfo onVehicleDataFetched={function (data: any): void {
          throw new Error('Function not implemented.');
        } } />} />
        <Route path="cancel" element={<Cancel />} />
      </Routes>
    </UploadProvider>
  );
}

export default MobileDriverOnboard;
