import LogoMobile from '../../logoMobile';
import { useNavigate } from 'react-router-dom';
import { useState} from 'react';

const Cancel = () => {
    const navigate = useNavigate();
    const [isPaused, setIsPaused] = useState(false);

    const handlePauseAndExit = () => {
        setIsPaused(true);
      };

      const handleContinue = () => {
        navigate(-1);  // Navigate back to the previous page
      };

  return (
    <div className="confirmation-container mobile-main">
        <div className="mobile-header">
            <LogoMobile />
        </div>
        <div className="mobile-body">
            <p className="confirmation-message">
            {isPaused ? 
            "Your information has been saved! Use the email link to finish up the process." : 
            "Do you want to pause your onboarding process?"
          }
        </p>
        {!isPaused && (
          <>
            <button className="confirmation-button" onClick={handlePauseAndExit}>
              Yes, pause and exit
            </button>
            <button className="confirmation-button" onClick={handleContinue}>
              No, keep going
            </button>
          </>
        )}
                {/* Do you want to pause your onboarding process?
            </p>
            <button className="confirmation-button">
                Yes, pause and exit
            </button>
            <button className="confirmation-button">
                No, keep going
            </button> */}
        </div>  
      
    </div>
  );
};

export default Cancel;
