// src/components/mobileDriverOnboard/upload.tsx
import { useNavigate } from 'react-router-dom';
import { useUploadContext } from '../../../context/uploadProvider';
import './upload.css';  
import Header from '../progressDots/index';

function DriverLicenseUpload(): JSX.Element {

  const { uploadType, setCaptureSide, setNextCaptureSide } = useUploadContext();
  const navigate = useNavigate();

  const handleTakePhotos = (side:string, nextSide:string) => {
    setCaptureSide(side);
    setNextCaptureSide(nextSide);
    navigate('/mobileDriverOnboard/capture');
  };

  return (
    <div className="driver-license-upload mobile-main">
      <Header />
      <div className='mobile-body dl-upload'>
        <h2 className='upload-h2'>Upload {uploadType === 'driverLicense' ? "Driver's License" : "Insurance Card"}</h2>
        <div className="image-placeholder">
          <div className="image-placeholder-icon"></div>
          <div className="image-placeholder-text"></div>
        </div>
        {/* <button className="photo-button" onClick={handleTakePhotos}>TAKE PHOTOS</button> */}
        <button className="photo-button" onClick={() => handleTakePhotos('front', 'back')}>TAKE PHOTO FRONT</button>
        <button className="photo-button" onClick={() => handleTakePhotos('back', 'front')}>TAKE PHOTO BACK</button>
      </div>
    </div>
  );
}

export default DriverLicenseUpload;
