import LogoMobile from "../../logoMobile";
import '../../../css/mobileDriverOnboard.css';
import './progressDots.css';

const Header = () => {
  return (
    <>
    <div className="mobile-header">
            <LogoMobile />
            <h1 className='capture-h1'>Account Setup</h1>
            <div className="progress-dots">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
            </div>
        </div>
    </>
  );
};

export default Header;
