import { useNavigate } from 'react-router-dom';
import './driverProfile.css';  // Import the CSS file
import Logo from "../../logo";
import Header from '../progressDots/index';

function Profile(): JSX.Element {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/mobileDriverOnboard/personal-vehicle');
  }

  return (
    <div className="account-setup mobile-main">
      <Header />
      <div className='mobile-body'>
        <div className="profile-form-section">
          {/* <h2 className="profile-h2">Enter your information</h2> */}
          <form>
            <div className="input-group">
              <input 
                type="text" 
                id="firstName" 
                name="firstName" 
                placeholder="First Name"
              />
            </div>
            <div className="input-group">
              <input 
                type="text" 
                id="lastName" 
                name="lastName" 
                placeholder="Last Name"
              />
            </div>
            <div className="input-group">
              <input 
                type="text" 
                id="streetAddress" 
                name="streetAddress" 
                placeholder="Street Address"
              />
            </div>
            <div className="input-group">
              <input 
                type="text" 
                id="city" 
                name="city" 
                placeholder="City"
              />
            </div>
            <div className="input-group">
              <select id="state" name="state">
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div className="input-group">
              <input 
                type="text" 
                id="zip" 
                name="zip" 
                placeholder="ZIP"
              />
            </div>
            <div className="input-group">
              <select id="gender" name="gender">
                <option value="female">Female</option>
                <option value="male">Male</option>
              </select>
            </div>
            <div className="input-group">
              <select id="maritalStatus" name="maritalStatus">
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="divorced">Divorced</option>
                <option value="widowed">Widowed</option>

              </select>
            </div>
          </form>
        </div>
        <div className="form-section secondary">
          <h2 className="profile-h2">Emergency Contact</h2>
          <form>
            <div className="input-group">
              <input 
                type="text" 
                id="emergencyContact" 
                name="emergencyContact" 
                placeholder="Emergency Contact"
              />
            </div>
            <div className="input-group">
              <input 
                type="text" 
                id="relationship" 
                name="relationship" 
                placeholder="Relationship"
              />
            </div>
            <div className="input-group">
              <input 
                type="text" 
                id="phoneNumber" 
                name="phoneNumber" 
                placeholder="Phone Number"
              />
            </div>
          </form>
          <div className="profile-button-container">
            <button className="profile-continue-button" onClick={handleContinue}>CONTINUE</button>
            <button className="profile-back-button">BACK</button>
          </div>  
        </div>
      </div>
    </div>
  );
}

export default Profile;
